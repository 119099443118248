import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import withApollo from 'next-with-apollo'
import fetch from 'isomorphic-unfetch'
import { createUploadLink } from 'apollo-upload-client'
import { from, split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'

const GRAPHQL_URL =
  process.env.NEXT_PUBLIC_API || 'http://localhost:3333/graphql'
const GRAPHQL_URL_WS =
  process.env.NEXT_PUBLIC_API_WS || 'ws://localhost:3333/graphql'

const httpLink = createUploadLink({
  fetch,
  uri: GRAPHQL_URL
})

const getToken = () => {
  if (process.browser) {
    return localStorage.getItem('learn49-token')
  }
  return ''
}

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken()

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }))
  return forward(operation)
})

const wsLink = process.browser
  ? new WebSocketLink({
      uri: GRAPHQL_URL_WS,
      options: {
        lazy: true,
        timeout: 1000,
        reconnect: true
      }
    })
  : null

const splitLink = process.browser
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
  : httpLink

export const client = new ApolloClient({
  link: from([authLink, splitLink]),
  cache: new InMemoryCache(),
  cacheRedirects: {
    Query: {
      course: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Course', id: args.id })
    }
  }
})

export default withApollo(
  ({ initialState }) =>
    new ApolloClient({
      link: from([authLink, splitLink]),
      cache: new InMemoryCache().restore(initialState || {})
    })
)
